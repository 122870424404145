import { render, staticRenderFns } from "./diamond.vue?vue&type=template&id=5a3507cc&scoped=true"
import script from "./diamond.vue?vue&type=script&lang=js"
export * from "./diamond.vue?vue&type=script&lang=js"
import style0 from "./diamond.vue?vue&type=style&index=0&id=5a3507cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3507cc",
  null
  
)

component.options.__file = "diamond.vue"
export default component.exports